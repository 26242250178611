import {
    useState
} from 'react'
  
import {
    Button,
    Card,
    CardBody,
    Select,
} from '@chakra-ui/react'

import { baseUrl } from '../env'

export default function AdminSettingsForm() {
    const [settingValue, setSettingValue] = useState(null as null | string | number | boolean)
    const [isLoading, setIsLoading] = useState(false)
    const [didSucceed, setDidSucceed] = useState(false)
    const [didError, setDidError] = useState(false)

    const handleButtonPress = async () => {
      setIsLoading(true)
      const settingType = (document.getElementById('settingType') as HTMLSelectElement).value
      const response = await fetch(`${baseUrl}/adhoc`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(({
            type: 'setting',
            text: `${settingType}=${settingValue}`
          }))
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
      <Card>
        <CardBody>
        <Select id='settingType'>
            <option value=''>Select a setting type</option>
            <option
                key='lookbackDays'
                value='lookbackDays'
            >
                Lookback days
            </option>
        </Select>
        <input
            onChange={e => { setSettingValue(e.target.value) }}
            style={{
                border: '1px solid #E2E8F0',
                borderRadius: '5px',
                marginTop: '0.5rem',
                padding: '0.5rem',
                width: '100%',
            }}
        />
        </CardBody>
        <Button
          isLoading={isLoading}
          colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
          onClick={handleButtonPress}
        >
          {didSucceed ? 'Created setting' : didError ? 'Failed to create setting' : 'Create setting' }
        </Button>
      </Card>
    )
}