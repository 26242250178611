import {
    useEffect,
    useState
} from 'react'

import {
    Button,
    Card,
    CardBody,
    Select,
} from '@chakra-ui/react'

import { baseUrl } from '../env'

export default function AdminSpendForm() {
    const [rewardQuantity, setRewardQuantity] = useState(NaN)
    const [quantity, setQuantity] = useState(0)
    const [comment, setComment] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [didSucceed, setDidSucceed] = useState(false)
    const [didError, setDidError] = useState(false)

    useEffect(() => {
        const fetchRewardQuantity = async () => {
            const response = await fetch(`${baseUrl}/rewards/stats?simple=true`)
            if (response.ok) {
                const { pointsAvailable } = await response.json()
                setRewardQuantity(pointsAvailable)
            }
        }
        fetchRewardQuantity()
    }, [])

    const handleButtonPress = async () => {
      setIsLoading(true)
      const type = (document.getElementById('spendingType') as HTMLSelectElement).value
      const response = await fetch(`${baseUrl}/handlers/spendReward`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(({
            rewardName: type,
            quantity,
            comment
          }))
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            const pointsUpdateResponse = await fetch(`${baseUrl}/rewards/stats?simple=true`)
            if (pointsUpdateResponse.ok) {
                const { pointsAvailable } = await pointsUpdateResponse.json()
                setRewardQuantity(pointsAvailable)
            }
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
      <Card>
        <CardBody>
        <p
            style={{
                marginLeft: '2px',
                marginBottom: '0.5rem',
            }}
        >{rewardQuantity ? `Points available: ${rewardQuantity}` : 'Fetching points'}</p>
        <Select id='spendingType'>
            <option value='$'>Regular dollar</option>
            <option value='hp$'>Half-price dollar</option>
            <option value='qp$'>Quarter-price dollar</option>
            <option value='Childhood photo'>Childhood photo</option>
        </Select>
        <input
            onChange={e => { setQuantity(+e.target.value) }}
            value={quantity}
            style={{
                border: '1px solid #E2E8F0',
                borderRadius: '5px',
                marginTop: '0.5rem',
                padding: '0.5rem',
                width: '100%',
            }}
        />
        <input
            onChange={e => { setComment(e.target.value) }}
            value={comment}
            placeholder='Comment'
            style={{
                border: '1px solid #E2E8F0',
                borderRadius: '5px',
                marginTop: '0.5rem',
                padding: '0.5rem',
                width: '100%',
            }}
        />
        </CardBody>
        <Button
          isLoading={isLoading}
          colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
          onClick={handleButtonPress}
        >
          {didSucceed ? 'Spent rewards' : didError ? 'Failed to spend rewards' : 'Spend rewards' }
        </Button>
      </Card>
    )
}