import {
  useEffect,
  useState
} from 'react'

import _ from 'lodash'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
} from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'

import AdminAddForm from './AdminAddForm'
import AdminConsequencesForm from './AdminConsequencesForm'
import AdminDelayForm from './AdminDelayForm'
import AdminFailForm from './AdminFailForm'
import AdminIncentivizeForm from './AdminIncentivizeForm'
import AdminSettingsForm from './AdminSettingsForm'
import AdminSpendForm from './AdminSpendForm'
import AdminSuperTaskForm from './AdminSupertaskForm'
import AdminTaskForm from './AdminTaskForm'
import AdminUncompleteForm from './AdminUncompleteForm'

import { baseUrl, IS_DEV } from '../env'
import dayjs from '../dayjs'
import Login from './Login'

import { IsoTime } from '../types/branded'
import { Task, TaskOccurrence } from '../types/models'
import { StateUser } from '../types/utility'

const possibleSections = {
  delay: ['Delay task', 0],
  task: ['Create task', 1],
  supertask: ['Create supertask', 2],
  consequences: ['Apply consequences', 3],
  incentivize: ['Incentivize task', 4],
  add: ['Add task', 5],
  fail: ['Fail task', 6],
  uncomplete: ['Uncomplete task', 7],
  spendReward: ['Spend reward', 8],
  settings: ['Settings', 9],
} as const
type PossibleSection = keyof typeof possibleSections

type Location = {
  latitude: number,
  longitude: number,
  createdAt: IsoTime,
  friendlyLocation: string | null
}

export default function Admin() {
    const [isAdminViewOn, setIsAdminViewOn] = useState(localStorage.getItem('adminView') === 'true')
    const [lastRequestTime, setLastRequestTime] = useState<IsoTime|null>(null)
    const [location, setLocation] = useState<Location|null>(null)
    const [tasks, setTasks] = useState([] as Task[])
    const [occurrences, setOccurrences] = useState([] as TaskOccurrence[])

    const [formSelection, setFormSelection] = useState('delay') as [PossibleSection, StateUser<PossibleSection>]

    useEffect(() => {
      const fn = async () => {
        if (!document.cookie.includes('admin_token=') && !IS_DEV) return

        fetch(`${baseUrl}/admin/lastRequestTime`)
        .then(res => res.json())
        .then(data => setLastRequestTime(data.lastRequestTime))

        fetch(`${baseUrl}/adhoc?type=location`)
        .then(res => res.json())
        .then(data => {
          const latestLocation = _.orderBy(data, 'createdAt', 'desc')[0]
          if (latestLocation) {
            const { latitude, longitude, friendlyLocation } = latestLocation.json
            setLocation({
              latitude: latitude.toFixed(4),
              longitude: longitude.toFixed(4),
              friendlyLocation,
              createdAt: latestLocation.createdAt
            })
          }
        })

        fetch(`${baseUrl}/tasks`)
        .then(res => res.json())
        .then(data => setTasks(data))

        fetch(`${baseUrl}/tasks/occurrences/simple`)
        .then(res => res.json())
        .then(data => setOccurrences(data))
      }
      fn()
    }, [])

    return (
    !document.cookie.includes('admin_token=') && !IS_DEV ?
    <Center>
      <VStack>
        <Login />
      </VStack>
    </Center> :
    <Center>
      <VStack>
      <Button
        onClick={() => {
          localStorage.setItem('adminView', isAdminViewOn ? 'false' : 'true')
          setIsAdminViewOn(!isAdminViewOn)
        }}
      >
        Turn admin view { isAdminViewOn ? 'off' : 'on' }
      </Button>
        <div>Last request time: { lastRequestTime ?
          dayjs(lastRequestTime).format('h:mm A [on] dddd') :
          'No data'
        }</div>
        <div>{ location ?
          <div>
            Last location: {location.friendlyLocation || <a href={`https://www.google.com/maps/search/?api=1&query=${location.latitude}%2C${location.longitude}`} target="_blank" rel="noreferrer">
              {`${location.latitude}, ${location.longitude}`}
            </a>} at {dayjs(location.createdAt).format('h:mm A [on] dddd')}
          </div> :
          'No location data'
        }</div>
        <Menu>
        <MenuButton
            px={4}
            py={2}
            transition='all 0.2s'
            borderRadius='md'
            borderWidth='1px'
            _hover={{ bg: 'gray.400' }}
            _expanded={{ bg: 'blue.400' }}
            _focus={{ boxShadow: 'outline' }}
        >
            <HStack>
                <Heading size="md">
                    {possibleSections[formSelection][0]}
                </Heading>
                <ChevronDownIcon />
            </HStack>
        </MenuButton>
        <MenuList>
          {Object.entries(possibleSections).map(([sectionKey, sectionVal]) => (
            <MenuItem
              key={sectionKey}
              onClick={() => setFormSelection(sectionKey as PossibleSection)}
            >{sectionVal[0]}</MenuItem>
          ))}
        </MenuList>
    </Menu>
        {[
          <AdminDelayForm taskOccurrences={occurrences}/>,
          <AdminTaskForm />,
          <AdminSuperTaskForm tasks={tasks}/>,
          <AdminConsequencesForm />,
          <AdminIncentivizeForm tasks={tasks}/>,
          <AdminAddForm tasks={tasks}/>,
          <AdminFailForm taskOccurrences={occurrences}/>,
          <AdminUncompleteForm taskOccurrences={occurrences}/>,
          <AdminSpendForm />,
          <AdminSettingsForm />,
        ][possibleSections[formSelection][1]]}
      </VStack>
    </Center>
    )
}